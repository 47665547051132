/* eslint-disable react/display-name */
import * as React from 'react';
import {TPAComponentsProvider} from 'wix-ui-tpa';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import settingsParams from './components/GridGallery/settingsParams';

let Provider;
let Consumer;
let strategy: (props: any) => {globals: any; locals: any};

export type Strategy<T> = (props: any) => {globals: T; locals: any};

export function withGlobalsProvider(Component, sliderGallery) {
  return (props) => {
    const {
      globals,
      locals,
      globals: {shouldShowMobile, isRTL},
    } = strategy(props);

    globals.styles = useStyles();

    if (globals.textsMap) {
      const {get: getSettings} = useSettings();

      if (!sliderGallery) {
        globals.textsMap.loadMoreButtonText =
          getSettings(settingsParams.LOAD_MORE_BUTTON) || globals.textsMap.loadMoreButtonText;
        globals.textsMap.loadPreviousButtonText =
          getSettings(settingsParams.gallery_loadPreviousText) || globals.textsMap.loadPreviousButtonText;
        globals.textsMap.filtersTitleText =
          getSettings(settingsParams.FILTERS_MAIN_TITLE) || globals.textsMap.filtersTitleText;
        globals.textsMap.sortTitleText =
          getSettings(settingsParams.SORTING_MAIN_TITLE) || globals.textsMap.sortTitleText;
      }

      globals.textsMap.addToCartOutOfStock =
        getSettings(settingsParams.gallery_oosButtonText) || globals.textsMap.addToCartOutOfStock;
      globals.textsMap.galleryAddToCartButtonText =
        getSettings(settingsParams.gallery_addToCartText) || globals.textsMap.galleryAddToCartButtonText;
      globals.textsMap.galleryAddToCartPreOrderButtonText =
        getSettings(settingsParams.gallery_preOrderText) || globals.textsMap.galleryAddToCartPreOrderButtonText;
      globals.textsMap.categoryTreeTitle =
        getSettings(settingsParams.categoryTreeTitle) || globals.textsMap.categoryTreeTitle;
    }

    return (
      <Provider value={globals}>
        <TPAComponentsProvider value={{mobile: shouldShowMobile, rtl: isRTL}}>
          <Component {...locals} />
        </TPAComponentsProvider>
      </Provider>
    );
  };
}

export const withGlobals = (Component): any => {
  return (props) => {
    return <Consumer>{(globals) => <Component globals={globals} {...props} ref={props.innerRef} />}</Consumer>;
  };
};

export function initContext<T>(aStrategy: Strategy<T>) {
  const ctx = React.createContext<T>(undefined);
  strategy = aStrategy;
  Provider = ctx.Provider;
  Consumer = ctx.Consumer;
}
